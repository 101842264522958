.BuilderContainer [class*='builder-text'] ul,
.BuilderContainer [class*='builder-text'] ol {
  margin-left: 1.5rem;
}

.BuilderContainer [class*='builder-text'] ul li {
  list-style: disc;
  text-align: left;
}

.BuilderContainer [class*='builder-text'] ol li {
  list-style: decimal;
  text-align: left;
}
